<template>
  <div class="page-view login">
    <img class="bg-image" src="@/assets/img/bg_login_2.png" />
    <!-- <Particles
      id="tsparticles"
      :options="particlesOptions"
    /> -->
    <div class="form-wrapper">
      <h1>Login</h1>
      <p>
        Inserire un nome utente e una password validi per accedere al pannello di controllo.
      </p>
      <Form @submit="tryLogin">
        <Field name="name" rules="req" placeholder="Nome utente"/>
        <ErrorMessage name="name" />
        <Field name="password" rules="req" type="password" placeholder="Password"/>
        <ErrorMessage name="password" />
        <button type="submit" class="btn submit">
          Accedi
        </button>
      </Form>
    </div>
  </div>
</template>

<script>
// https://vee-validate.logaretm.com/v4/guide/components/validation
import { Field, Form, ErrorMessage } from 'vee-validate'

export default {
  components: {
    Field,
    Form,
    ErrorMessage
  },
  data () {
    return {
      particlesOptions: {
        fullScreen: {
          enable: false
        },
        background: {
          color: {
            value: '#0d47a1'
          }
        },
        fpsLimit: 20,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: 'push'
            },
            onHover: {
              enable: false
              // mode: 'repulse'
            },
            resize: true
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40
            },
            push: {
              quantity: 4
            },
            repulse: {
              distance: 200,
              duration: 0.4
            }
          }
        },
        particles: {
          ZIndex: '-10',
          color: {
            value: '#ffffff'
          },
          links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
          },
          collisions: {
            enable: true
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 2,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            value: 40
          },
          opacity: {
            value: 0.5
          },
          shape: {
            type: 'circle'
          },
          size: {
            random: true,
            value: 2
          }
        },
        detectRetina: true
      }
    }
  },
  methods: {
    async tryLogin (values) {
      const form = new FormData()
      form.append('username', values.name)
      form.append('password', values.password)
      const logged = await this.$store.dispatch('logIn', form)
      if (logged) {
        this.$store.dispatch('getUser')
        this.$router.push('/')
      } else {
        const error = true
        const msg = 'ERRORE durante il login: credenziali errate.'
        this.emitter.emit('fireAlert', { msg, error })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/views/login.scss";
</style>
